const CACHE = new Map();
// Example:

// ```js
// import  {search} from "@opendash/core";

// const rows = [
//   {
//     title: "hello world",
//     description: "some description",
//     category: "Demo",
//     tags: ["some", "tags"],
//   },
//   {
//     title: "Title 2",
//     description: "a description",
//     category: "With Space",
//     tags: ["other", "tags"],
//   },
//   // ...
// ];

// // allow search for title
// const mapper1 = (row) => row.title;

// // allow search for title and description
// const mapper2 = (row) => [row.title, row.description];

// // allow search for title and description, allow filter by category and tags
// const mapper3 = (row) => ({
//   text: [row.title, row.description],
//   keys: {
//     category: row.category,
//     tag: row.tags,
//   },
// });

// console.log(
//   `search result for: "hello world":`,
//   search(`hello world`, rows, mapper1)
// );

// console.log(
//   `search result for: "description":`,
//   search(`description`, rows, mapper2)
// );

// console.log(
//   `search result for: "hello world":`,
//   search(`hello world`, rows, mapper3)
// );

// console.log(
//   `search result for: "'hello world'":`,
//   search(`'hello world'`, rows, mapper3)
// );

// console.log(
//   `search result for: "hello category:demo":`,
//   search(`hello category:demo`, rows, mapper3)
// );

// console.log(
//   `search result for: "hello category:'with space'":`,
//   search(`hello category:'with space'`, rows, mapper3)
// );

// console.log(
//   `search result for: "hello tag:some":`,
//   search(`hello tag:some`, rows, mapper3)
// );

// console.log(
//   `search result for: "hello tag:some tag:tags":`,
//   search(`hello tag:some tag:tags`, rows, mapper3)
// );

// console.log(
//   `search result for: "hello tag:tags -tag:some":`,
//   search(`hello tag:tags -tag:some`, rows, mapper3)
// );
// ```




























export function search(
  searchString,
  data,
  mapper
) {
  return data.filter((d) => evaluateSearchString(searchString, mapper(d)));
}

export function evaluateSearchString(
  searchString = "",
  data
) {
  if (!data) {
    return false;
  }

  const search = parseSearchString(searchString, typeof data === "string");

  if (Array.isArray(data)) {
    if (search.texts.length === 0) {
      return true;
    }

    if (data.length === 0) {
      return true;
    }

    return data.some((i) => evaluateSearchString(search, i));
  }

  if (typeof data === "string") {
    if (search.texts.length === 0) {
      return true;
    }

    return search.texts.every(({ text, negated }) =>
      negate(data.toLowerCase().includes(text), negated)
    );
  }

  const match = !data.text ? true : evaluateSearchString(search, data.text);

  if (!match || !search.conditions) {
    return match;
  }

  if (data.keys) {
    // debugger;
    for (const [key, conditions] of Object.entries(search.conditions)) {
      const value = data.keys[key];

      if (!value) {
        return false;
      }

      const conditionMatch = conditions.every((condition) =>
        negate(
          Array.isArray(value)
            ? value
                .map((v) => v.toLowerCase())
                .includes(condition.value.toLowerCase())
            : value.toLowerCase() === condition.value,
          condition.negated
        )
      );

      if (!conditionMatch) {
        return false;
      }
    }
  }

  return true;
}

// Credits: https://github.com/mixmaxhq/search-string/blob/master/src/searchString.js
// TODO: implement skipConditions: might help with performance
export function parseSearchString(
  input = "",
  skipConditions = false
) {
  if (typeof input !== "string") {
    return input;
  }

  input = input.toLowerCase();

  if (CACHE.has(input)) {
    return CACHE.get(input);
  }

  var State; (function (State) {
    const RESET = "RESET"; State["RESET"] = RESET;
    const IN_OPERAND = "IN_OPERAND"; State["IN_OPERAND"] = IN_OPERAND;
    const IN_TEXT = "IN_TEXT"; State["IN_TEXT"] = IN_TEXT;
  })(State || (State = {}));

  var QuoteState; (function (QuoteState) {
    const RESET = "RESET"; QuoteState["RESET"] = RESET;
    const SINGLE_QUOTE = "SINGLE_QUOTE"; QuoteState["SINGLE_QUOTE"] = SINGLE_QUOTE;
    const DOUBLE_QUOTE = "DOUBLE_QUOTE"; QuoteState["DOUBLE_QUOTE"] = DOUBLE_QUOTE;
  })(QuoteState || (QuoteState = {}));

  const conditions = {};
  const texts = [];

  const addCondition = (key, value, negated) => {
    if (!conditions[key]) {
      conditions[key] = [];
    }

    conditions[key].push({ value, negated });
  };

  const addTextSegment = (text, negated) => {
    texts.push({ text, negated });
  };

  let state;
  let quoteState;
  let isNegated;
  let currentOperand;
  let currentText;
  let prevChar;

  const performReset = () => {
    state = State.RESET;
    quoteState = QuoteState.RESET;
    isNegated = false;
    currentOperand = "";
    currentText = "";
    prevChar = "";
  };

  // Terminology, in this example: 'to:joe@acme.com'
  // 'to' is the operator
  // 'joe@acme.com' is the operand
  // 'to:joe@acme.com' is the condition

  // Possible states:
  const inText = () => state === State.IN_TEXT; // could be inside raw text or operator
  const inOperand = () => state === State.IN_OPERAND;
  const inSingleQuote = () => quoteState === QuoteState.SINGLE_QUOTE;
  const inDoubleQuote = () => quoteState === QuoteState.DOUBLE_QUOTE;
  const inQuote = () => inSingleQuote() || inDoubleQuote();

  performReset();

  const quotePairMap = getQuotePairMap(input);

  for (let i = 0; i < input.length; i++) {
    const char = input[i];
    if (char === " ") {
      if (inOperand()) {
        if (inQuote()) {
          currentOperand += char;
        } else {
          addCondition(currentText, currentOperand, isNegated);
          performReset();
        }
      } else if (inText()) {
        if (inQuote()) {
          currentText += char;
        } else {
          addTextSegment(currentText, isNegated);
          performReset();
        }
      }
    } else if (char === "," && inOperand() && !inQuote()) {
      addCondition(currentText, currentOperand, isNegated);
      // No reset here because we are still evaluating operands for the same operator
      currentOperand = "";
    } else if (char === "-" && !inOperand() && !inText()) {
      isNegated = true;
    } else if (char === ":" && !inQuote()) {
      if (inOperand()) {
        // If we're in an operand, just push the string on.
        currentOperand += char;
      } else if (inText()) {
        // Skip this char, move states into IN_OPERAND,
        state = State.IN_OPERAND;
      }
    } else if (char === '"' && prevChar !== "\\" && !inSingleQuote()) {
      if (inDoubleQuote()) {
        quoteState = QuoteState.RESET;
      } else if (quotePairMap.double[i]) {
        quoteState = QuoteState.DOUBLE_QUOTE;
      } else if (inOperand()) {
        currentOperand += char;
      } else {
        currentText += char;
      }
    } else if (char === "'" && prevChar !== "\\" && !inDoubleQuote()) {
      if (inSingleQuote()) {
        quoteState = QuoteState.RESET;
      } else if (quotePairMap.single[i]) {
        quoteState = QuoteState.SINGLE_QUOTE;
      } else if (inOperand()) {
        currentOperand += char;
      } else {
        currentText += char;
      }
    } else if (char !== "\\") {
      // Regular character..
      if (inOperand()) {
        currentOperand += char;
      } else {
        currentText += char;
        state = State.IN_TEXT;
      }
    }
    prevChar = char;
  }
  // End of string, add any last entries
  if (inText()) {
    addTextSegment(currentText, isNegated);
  } else if (inOperand()) {
    addCondition(currentText, currentOperand, isNegated);
  }

  const result = { input, texts, conditions };

  CACHE.set(input, result);

  return result;
}

function getQuotePairMap(str = "") {
  const quotePairMap = { single: {}, double: {} };

  const prevQuote = { single: -1, double: -1 };
  let prevChar = "";
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    if (prevChar !== "\\") {
      if (char === '"') {
        if (prevQuote.double >= 0) {
          quotePairMap.double[prevQuote.double] = true;
          quotePairMap.double[i] = true;
          prevQuote.double = -1;
        } else {
          prevQuote.double = i;
        }
      } else if (char === "'") {
        if (prevQuote.single >= 0) {
          quotePairMap.single[prevQuote.single] = true;
          quotePairMap.single[i] = true;
          prevQuote.single = -1;
        } else {
          prevQuote.single = i;
        }
      }
    }
    prevChar = char;
  }

  return quotePairMap;
}

function negate(input, shouldNegate) {
  if (shouldNegate) {
    return !input;
  }

  return input;
}
