















export class AuthError extends Error {
  

  constructor(type) {
    super("[@opendash/core] AUTH ERROR: " + type);

    this.type = type;
  }
}
