
import { makeAutoObservable, runInAction } from "mobx";












export class CommandBarService {
  
   __init() {this.commandMap = new Map()}

  constructor(framework) {;CommandBarService.prototype.__init.call(this);
    makeAutoObservable(this);

    this.framework = framework;
  }

   get actions() {
    return Array.from(this.commandMap.values()).map((command) => ({
      id: command.id,
      parent: command.parent,
      name: command.title,
      subtitle: command.description,
      icon: command.icon,
      shortcut: command.shortcut,
      perform:
        typeof command.handler === "function"
          ? () => command.handler()
          : undefined,
    }));
  }

   registerCommand(command) {
    const id = command.id;

    this.commandMap.set(id, command);

    return () => {
      runInAction(() => {
        this.commandMap.delete(id);
      });
    };
  }

   registerCommands(commands) {
    const remover = commands.map((command) => this.registerCommand(command));

    return () => {
      for (const remove of remover) {
        remove();
      }
    };
  }
}
