 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import {
  RouterStatefullHandler,


} from "@opendash/router";
import {

  Navigate,

  createBrowserRouter,
} from "@opendash/router/dist/internal";
import { makeAutoObservable, runInAction } from "mobx";
import React from "react";
import {
  RouterErrorHandler,
  RouterLayoutBasic,
  RouterLayoutBasicPadding,
  RouterRootHandler,
} from "..";









export class RouterService {
  
   __init() {this.__router = null}

   __init2() {this.history = []}

   __init3() {this.pathname = window.location.pathname}
   __init4() {this.search = window.location.search}
   __init5() {this.url = this.pathname + this.search}

   __init6() {this.searchParams = getSearchParams(this.search)}
  // public params: ParamsType = {};

  constructor(framework) {;RouterService.prototype.__init.call(this);RouterService.prototype.__init2.call(this);RouterService.prototype.__init3.call(this);RouterService.prototype.__init4.call(this);RouterService.prototype.__init5.call(this);RouterService.prototype.__init6.call(this);
    makeAutoObservable(this);

    this.framework = framework;
  }

   getURL() {
    return new URL(window.location.href);
  }

   navigate(url, options) {
    if (url instanceof URL) {
      url = url.pathname + url.search;
    }

    if (_optionalChain([options, 'optionalAccess', _ => _.backIfPossible]) && this.history.length > 0) {
      if (url === _optionalChain([this, 'access', _2 => _2.history, 'access', _3 => _3.at, 'call', _4 => _4(-1), 'optionalAccess', _5 => _5.url])) {
        return void this.back();
      }
    }

    if (_optionalChain([options, 'optionalAccess', _6 => _6.keepQueryParams])) {
      url += this.search;
    }

    if (_optionalChain([options, 'optionalAccess', _7 => _7.replace])) {
      this.replaceState(url);
    } else {
      this.pushState(url);
    }
  }

   isBackAvailable() {
    return this.history.length > 0;
  }

   back() {
    if (this.isBackAvailable()) {
      this.history.pop();
      this.go(-1);
    }
  }

   go(delta) {
    _optionalChain([this, 'access', _8 => _8.__router, 'optionalAccess', _9 => _9.navigate, 'call', _10 => _10(delta)]);
  }

   pushState(url) {
    _optionalChain([this, 'access', _11 => _11.__router, 'optionalAccess', _12 => _12.navigate, 'call', _13 => _13(url)]);
  }

   replaceState(url) {
    _optionalChain([this, 'access', _14 => _14.__router, 'optionalAccess', _15 => _15.navigate, 'call', _16 => _16(url, { replace: true })]);
  }

   setSearchParam(
    key,
    value,
    options
  ) {
    const params = new URLSearchParams(this.search);

    if (value) {
      params.set(key, value);
    } else {
      params.delete(key);
    }

    const search = params.toString();

    if (search) {
      this.navigate(this.pathname + "?" + search, options);
    } else {
      this.navigate(this.pathname, options);
    }
  }

   __getRoutes() {
    if (this.__router) {
      return this.__router;
    }

    const routes = this.framework._internal.routes;

    this.__router = createBrowserRouter([
      {
        path: "/",
        element: React.createElement(RouterRootHandler),
        errorElement: React.createElement(RouterErrorHandler),
        children: [
          {
            errorElement: React.createElement(RouterErrorHandler),
            children: routes.map((route) => {
              const path = route.path;

              let element = null;

              const RouteLayout = getLayout(route.layout);

              if ("redirectPath" in route) {
                element = React.createElement(Navigate, {
                  to: route.redirectPath,
                  replace: true,
                });
              } else if ("state" in route && "componentSync" in route) {
                element = React.createElement(RouterStatefullHandler, {
                  key: path,
                  RouteState: route.state,
                  RouteComponent: route.componentSync,
                  RouteLayout,
                });
              } else if ("component" in route) {
                element = React.createElement(
                  React.lazy(route.component),
                  route.props || {}
                );
              } else if ("componentSync" in route) {
                element = React.createElement(
                  route.componentSync,
                  route.props || {}
                );
              }

              const loader = async ({
                request,
                params,
              }) => {
                if (!element) {
                  throw new Response("", {
                    status: 404,
                    statusText: "Element Not Found",
                  });
                }

                const authenticated =
                  !!this.framework.services.UserService.isLoggedIn();

                if (route.auth === "authenticated" && !authenticated) {
                  throw new Response("", {
                    status: 401,
                    statusText: "Unauthorized",
                  });
                }

                if (route.auth === "unauthenticated" && authenticated) {
                  return new Response("", {
                    status: 302,
                    headers: {
                      Location: "/",
                    },
                  });
                }

                const hasPermission =
                  !route.permission ||
                  (Array.isArray(route.permission)
                    ? route.permission.every((p) =>
                        this.framework.services.UserService.hasPermission(p)
                      )
                    : this.framework.services.UserService.hasPermission(
                        route.permission
                      ));

                if (!hasPermission) {
                  throw new Response("", {
                    status: 403,
                    statusText: "Permission denied.",
                  });
                }

                return null;
              };

              const childRoute = {
                path,
                element,
                loader,
              };

              return childRoute;
            }),
          },
        ],
      },
    ]);

    this.__router.subscribe((state) => {
      // Params:
      // console.log(state.matches.at(-1)?.params);

      const pathname = state.location.pathname;
      const search = state.location.search;
      const url = pathname + search;

      if (this.url === url) return;

      runInAction(() => {
        this.history.push({
          url: this.url,
          pathname: this.pathname,
          search: this.search,
        });

        this.url = url;
        this.pathname = pathname;
        this.search = search;
        this.searchParams = getSearchParams(search);
      });
    });

    return this.__router;
  }
}

function getSearchParams(search) {
  return Object.fromEntries(new URLSearchParams(search).entries());
}

function getLayout(
  layout
) {
  if (!layout) {
    return null;
  }

  if (typeof layout === "string") {
    if (layout === "basic") {
      return RouterLayoutBasic;
    }

    if (layout === "basic-padding") {
      return RouterLayoutBasicPadding;
    }

    console.warn(
      `[$framework.router] Layout '${layout}' not found. Fallback to no layout.`
    );

    return null;
  }

  return layout ;
}
