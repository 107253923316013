const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/SelectWithAll.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Divider, Select, TreeSelect, Typography } from "antd";

import React, { useEffect } from "react";
import { arrayToDistinct } from "../helper/arrayToDistinct";
import { treeToArray } from "../helper/arrayToTree";
const { Text } = Typography;

const ADD_CHILDREN_ON_PARENT_SELECT = false;
const ALL_VALUE = "SELECT_WITH_ALL_VALUE";
const DIVIDER_VALUE = "SELECT_WITH_ALL_NO_VALUE";

/**
 * A selection input with an option to select all options.
 */
export function SelectWithAll(props











) {
  // filter options to check if allOption already exists. if not => add it
  const allOptionExists = props.options.find((opt) => {
    opt.value === ALL_VALUE;
  });

  if (!allOptionExists && props.options.length > 0) {
    const divider = {
      label: React.createElement(Divider, { style: { margin: "10px 0" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}} ),
      disabled: true,
      value: DIVIDER_VALUE,
    };

    const allOption = {
      label: props.allLabel,
      value: ALL_VALUE,
    };

    props.options.push(divider);
    props.options.push(allOption);
  }

  // make all options distinct by value
  const optionsDistinct = arrayToDistinct(props.options, (opt) =>
    opt.value.toString()
  );

  // get all the options in a flat array because it could be an tree select
  const flatOptions = treeToArray(
    optionsDistinct,
    props.getTreeChildren ? props.getTreeChildren() : (obj) => obj.children
  );

  // if all options are present in the value array, display the allValue in selection
  const hasAll = hasAllSelected();
  const displayedValue = hasAll ? [ALL_VALUE] : props.value;

  useEffect(() => {
    if (props.value.some((val) => val === ALL_VALUE)) updateStates(props.value);
  }, [props.value]);

  // checks if all values of the options are in the value array
  function hasAllSelected() {
    return (
      props.value.some((val) => val === ALL_VALUE) ||
      props.value.length ===
        flatOptions.filter(
          (option) =>
            option.value !== ALL_VALUE && option.value !== DIVIDER_VALUE
        ).length
    );
  }

  // updates any changes and calls the onChange given to this component with the computed values
  function updateStates(values) {
    // if allValue is selected and another value is clicked, remove the allValue
    if (values.length > 1 && values[0] === ALL_VALUE) {
      const filteredValues = values.filter((value) => {
        return value !== ALL_VALUE;
      });

      const valuesWithChildren = getValuesWithChildren(filteredValues);
      props.onChange(valuesWithChildren);
      return;
    }

    const allOptionsIds = flatOptions
      .filter(
        (option) => option.value !== ALL_VALUE && option.value !== DIVIDER_VALUE
      )
      .map((option) => option.value);

    // if the allOption is clicked or all the options are being selected,
    // switch to allOption and change the value to the array containing all options values
    if (
      (values.length > 1 && values[values.length - 1] === ALL_VALUE) ||
      values.length === flatOptions.length - 1
    ) {
      props.onChange(allOptionsIds);
      return;
    }

    // if values still contains the allValue
    if (values.some((val) => val === ALL_VALUE)) {
      props.onChange(allOptionsIds);
      return;
    }

    const valuesWithChildren = getValuesWithChildren(values);

    props.onChange(valuesWithChildren);
  }

  // if selected value has children => also select those if ADD_CHILDREN_ON_PARENT_SELECT is true
  function getValuesWithChildren(valuesToCheck) {
    const returnValue = [...valuesToCheck];
    if (!ADD_CHILDREN_ON_PARENT_SELECT) return returnValue;

    valuesToCheck.forEach((value) => {
      // find the option of the checked value if it has children and was the value triggering the onChange event
      const optWithChilren = props.options.find(
        (opt) =>
          value === opt.value &&
          _optionalChain([opt, 'access', _ => _.children, 'optionalAccess', _2 => _2.length]) > 0 &&
          (!props.value.find((currentValue) => currentValue === value) ||
            hasAllSelected())
      );

      // if an option was found, add all children that are not already selected
      if (optWithChilren)
        optWithChilren.children.forEach((child) => {
          if (!valuesToCheck.includes(child.value))
            returnValue.push(child.value);
        });
    });
    return returnValue;
  }

  return (
    React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
      , React.createElement(Text, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}, props.title)
      , !props.isTree && (
        React.createElement(Select, {
          id: props.id,
          style: props.style,
          options: optionsDistinct,
          allowClear: true,
          mode: "multiple",
          onChange: updateStates,
          value: displayedValue,
          placeholder: props.placeholder,
          loading: props.loading, __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
        )
      )
      , props.isTree && (
        React.createElement(TreeSelect, {
          id: props.id,
          style: props.style,
          treeData: optionsDistinct,
          multiple: true,
          treeDefaultExpandAll: true,
          value: displayedValue,
          onChange: updateStates,
          loading: props.loading, __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
        )
      )
    )
  );
}
