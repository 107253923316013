 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }

export class SourceAdapterContext {
  
  

  constructor(service, app) {
    this.service = service;
    this.app = app;
  }

  setLoading(value) {
    this.service.setLoading(value);
  }

  setSources(sources) {
    this.app.state.update((draft) => {
      if (!sources || !Array.isArray(sources) || sources.length === 0) {
        draft.sources.all = [];
        draft.sources.current = null;
      } else {
        draft.sources.all = sources;

        const current = sources.find(
          (source) => source.id === _optionalChain([draft, 'access', _ => _.sources, 'access', _2 => _2.current, 'optionalAccess', _3 => _3.id])
        );

        if (!draft.sources.current || !current) {
          draft.sources.current = sources[0];
        } else {
          draft.sources.current = current;
        }
      }
    });

    this.service.notifySubscribers();
  }

  updateSources(id, source) {
    this.app.state.update((draft) => {
      if (_optionalChain([draft, 'access', _4 => _4.sources, 'access', _5 => _5.current, 'optionalAccess', _6 => _6.id]) === source.id) {
        Object.assign(draft.sources.current, source);
      }

      const draftSource = draft.sources.all.find((s) => s.id === source.id);

      if (draftSource) {
        Object.assign(draftSource, source);
      } else {
        draft.sources.all.push(source);
      }
    });

    this.service.notifySubscribers();
  }
}
