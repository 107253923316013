import * as React from "react";

import { useForceRender, useOpenDashServices } from "..";









/**
 * Returns
 *
 *    1. currently selected source as `SourceInterface`
 *    2. callback to set the current source
 *    3. all sources as `SourceInterface[]`
 *    4. loading state
 *    5. enabled state
 */
export function useSource() {
  const { SourceService } = useOpenDashServices();

  const forceRender = useForceRender();

  React.useEffect(() => {
    return SourceService.subscribe(() => {
      forceRender();
    });
  }, []);

  return [
    SourceService.getCurrent(),
    React.useCallback((input) => {
      SourceService.setCurrent(input);
    }, []),
    SourceService.getAll(),
    SourceService.isLoading(),
    SourceService.isEnabled(),
  ];
}
