const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/RouterLayoutBasic.tsx";
import { AdminToolbar } from "@opendash/ui";
import * as React from "react";
import { $framework, AdminLayout, useTranslation } from "..";

export const RouterLayoutBasic = React.memo




(function RouterLayoutBasic({ children, ctx, padding, actions }) {
  const t = useTranslation();

  const title = t(ctx.title);
  const description = t(ctx.description);

  React.useEffect(() => {
    window.document.title = `${title} | ${$framework.ui2.name}`;

    return () => {
      window.document.title = $framework.ui2.name;
    };
  }, [title]);

  return (
    React.createElement(AdminLayout, { contentPadding: padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
      , React.createElement(AdminToolbar, { title: title, description: description, actions: actions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}} )
      , React.createElement('div', { className: "od-page-main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}, children)
    )
  );
});
