const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/SourcePicker.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Icon } from "@opendash/icons";
import { Button, Drawer, List, TreeSelect, Typography } from "antd";
import * as React from "react";
import { useSource, useTranslation } from "..";









export function SourcePicker({
  value,
  onChange,
  style,
  asButton = false,
  allowClear = false,
}) {
  const [, , sources] = useSource();
  const [showDrawer, setShowDrawer] = React.useState(false);
  const treeData = React.useMemo(() => mapSources(sources), [sources]);
  const t = useTranslation();
  return (
    React.createElement(React.Fragment, null
      , !asButton && (
        React.createElement(TreeSelect, {
          style: style,
          disabled: sources.length < 2,
          value: _optionalChain([value, 'optionalAccess', _ => _.id]),
          onChange: (id) =>
            onChange(sources.find((source) => source.id === id))
          ,
          treeData: treeData,
          treeDefaultExpandAll: true,
          treeNodeFilterProp: "title",
          showSearch: true,
          allowClear: allowClear, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
        )
      )
      , asButton && (
        React.createElement(React.Fragment, null
          , React.createElement(Button, {
            style: { ...style, color: "var(--opendash-color-gray)" },
            ghost: true,
            icon: React.createElement(Icon, { icon: "fa:angle-down", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}} ),
            onClick: () => {
              setShowDrawer(true);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}

            , React.createElement(Typography.Text, { ellipsis: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}, _optionalChain([value, 'optionalAccess', _2 => _2.name]))
          )
          , React.createElement(Drawer, {
            open: showDrawer,
            closable: true,
            title: t("opendash:sources.label_plural"),
            placement: "top",
            height: "70%",
            onClose: () => {
              setShowDrawer(false);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}

            , React.createElement(List, {
              size: "large",
              dataSource: treeData,
              renderItem: (item) => {
                return renderItem(item, 0, (id) => {
                  onChange(sources.find((source) => source.id === id));
                  setShowDrawer(false);
                });
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
            )
          )
        )
      )
    )
  );
}
function renderItem(item, level, select) {
  return (
    React.createElement(React.Fragment, null
      , React.createElement(List.Item, {
        onClick: () => {
          select(item.value);
        },
        style: { marginLeft: "" + level * 20 + "px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}

        , item.title
        , React.createElement('span', { style: { float: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
          , React.createElement(Icon, { icon: "fa:chevron-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}})
        )
      )
      , item.children.map((child) => {
        return renderItem(child, level + 1, select);
      })
    )
  );
}
function mapSources(
  sources,
  parent = null,
  inTree = []
) {
  return sources
    .filter((source) => {
      if (inTree.includes(source.id)) {
        return false;
      }

      if (!parent) {
        return !source.parent;
      } else {
        return source.parent === parent;
      }
    })
    .map((source) => {
      return {
        title: source.name,
        value: source.id,
        children: mapSources(sources, source.id, [...inTree, source.id]),
      };
    });
}
