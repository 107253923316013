const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/FormGenerator.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import Schema from "async-validator";
import * as React from "react";

import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
} from "antd";

import { Icon } from "@opendash/icons";


import ReactMarkdown from "react-markdown";

import { produce, useTranslation } from "..";



// Avoid warnings from the validator in the console
// @ts-ignore
Schema.warning = () => {};


















export const FormGenerator = ({
  elements,
  state: externalState,
  updateState: externalUpdateState,
  onChange,
  submit: submitOptions,
  onSubmit,
  settings,
  children,
}) => {
  const t = useTranslation();

  const firstRunRef = React.useRef(true);
  const dirtyRef = React.useRef({});

  const [internalState, setInternalState] = React.useState({});
  const [errorState, setErrorState] = React.useState({});

  const schema = React.useMemo(() => {
    return new Schema(
      Object.fromEntries(
        elements
          .filter((field) => field.rules)
          .map((field) => [field.key, field.rules])
      )
    );
  }, [elements]);

  const hasExternalState = externalState && externalUpdateState;

  const state = hasExternalState ? externalState : internalState;

  const updateStateHandler = React.useCallback(
    (key, name, value) => {
      name = name || key;
      const nextState = produce(state, (draft) => {
        setSelector(draft, name, value);
      });

      if (hasExternalState) {
        const x = (Array.isArray(name) ? name[0] : name).toString();

        externalUpdateState(x, nextState[x]);
      } else {
        setInternalState(nextState);
      }

      if (!dirtyRef.current[key]) {
        dirtyRef.current[key] = true;
      }
    },
    [state, hasExternalState, externalUpdateState]
  );

  const visibleElements = elements.filter((field) => {
    if (field.visible === false) {
      return false;
    } else if (typeof field.visible === "function") {
      return field.visible(state);
    } else {
      return true;
    }
  });

  const invisibleElements = elements.filter((field) => {
    if (field.visible === true) {
      return true;
    } else if (typeof field.visible === "function") {
      return !field.visible(state);
    } else {
      return false;
    }
  });

  React.useEffect(() => {
    if (!firstRunRef.current) {
      if (onChange) {
        onChange(state);
      }

      // @ts-ignore
      schema.validate(state, {}, (errors) => {
        if (errors) {
          setErrorState(
            Object.fromEntries(
              errors.map((error) => [error.field, error.message])
            )
          );
        } else {
          setErrorState({});
        }
      });
    }

    firstRunRef.current = false;

    for (const field of visibleElements) {
      if (
        field.defaultValue !== undefined &&
        (field.key in state === false || state[field.key] === undefined)
      ) {
        updateStateHandler(field.key, field.name, field.defaultValue);
      }
    }

    if (_optionalChain([settings, 'optionalAccess', _ => _.removeHidden])) {
      for (const field of invisibleElements) {
        if (field.key in state === true && state[field.key] !== undefined) {
          updateStateHandler(field.key, field.name, undefined);
        }
      }
    }
  }, [state]);

  const layout = _optionalChain([settings, 'optionalAccess', _2 => _2.layout]) ? settings.layout : "vertical";

  return (
    React.createElement(Form, {
      layout: layout,
      onFinish: () => {
        if (onSubmit && !Object.values(errorState).some((e) => e)) {
          onSubmit(state);
        }
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}

      /* Replace Space */
      , React.createElement(Space, { direction: "vertical", style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 171}}
        , React.createElement(React.Fragment, null
          , visibleElements.map((field) => {
            return (
              React.createElement(Form.Item, {
                key: field.key,
                label: !_optionalChain([settings, 'optionalAccess', _3 => _3.hideLabels]) && t(field.label),
                // hasFeedback
                validateStatus: 
                  dirtyRef.current[field.key]
                    ? errorState[field.key]
                      ? "error"
                      : "success"
                    : undefined
                ,
                help: 
                  dirtyRef.current[field.key] && errorState[field.key] ? (
                    errorState[field.key]
                  ) : field.descriptionMarkdown ? (
                    React.createElement(ReactMarkdown, { children: field.descriptionMarkdown, __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}} )
                  ) : field.description ? (
                    t(field.description)
                  ) : undefined
                ,
                tooltip: 
                  field.hintMarkdown ? (
                    React.createElement(ReactMarkdown, { children: field.hintMarkdown, __self: this, __source: {fileName: _jsxFileName, lineNumber: 197}} )
                  ) : (
                    t(field.hint)
                  )
                , __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}}

                , React.createElement(FormGeneratorField, {
                  field: field,
                  value: getSelector(state, field.name || field.key),
                  setValue: (v) => {
                    updateStateHandler(field.key, field.name, v);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 203}}
                )
              )
            );
          })

          , children && { children }

          , submitOptions && (
            React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 217}}
              , React.createElement(Button, {
                type: "primary",
                htmlType: "submit",
                disabled: Object.values(errorState).some((e) => e),
                ...(submitOptions || {}), __self: this, __source: {fileName: _jsxFileName, lineNumber: 218}}
              )
            )
          )

          , !submitOptions && (
            React.createElement('div', { style: { display: "hidden" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 228}}
              /* <Button
                type="primary"
                htmlType="submit"
                disabled={Object.values(errorState).some((e) => e)}
              /> */
            )
          )
        )
      )
    )
  );
};







const FormGeneratorField = ({
  field,
  value,
  setValue,
}) => {
  const t = useTranslation();

  if (field.type === "input") {
    const { prefixIcon, ...settings } = field.settings || {};

    if (prefixIcon) {
      settings.prefix = (
        React.createElement(Icon, { icon: prefixIcon, style: { color: "rgba(0,0,0,.25)" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 260}} )
      );
    }

    return (
      React.createElement(Input, {
        ...settings,
        value: value,
        onChange: (e) => setValue(e.target.value),
        type: settings.type || "text",
        style: field.style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 265}}
      )
    );
  }

  if (field.type === "input.tags") {
    const seperator = _optionalChain([field, 'access', _4 => _4.settings, 'optionalAccess', _5 => _5.seperator]) || ",";

    return (
      React.createElement(Select, {
        mode: "tags",
        tokenSeparators: [seperator],
        value: value ? value.split(seperator) : [],
        onChange: (nextValue) => setValue(nextValue.join(seperator)),
        style: field.style,
        notFoundContent: null, __self: this, __source: {fileName: _jsxFileName, lineNumber: 279}}
      )
    );
  }

  if (field.type === "input.password") {
    const { prefixIcon, ...settings } = field.settings || {};

    if (prefixIcon) {
      settings.prefix = (
        React.createElement(Icon, { icon: prefixIcon, style: { color: "rgba(0,0,0,.25)" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 295}} )
      );
    }

    return (
      React.createElement(Input.Password, {
        value: value,
        onChange: (e) => setValue(e.target.value),
        placeholder: _optionalChain([field, 'access', _6 => _6.settings, 'optionalAccess', _7 => _7.placeholder]) || void 0,
        prefix: _optionalChain([field, 'access', _8 => _8.settings, 'optionalAccess', _9 => _9.prefix]) || null,
        style: field.style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 300}}
      )
    );
  }

  if (field.type === "input.number") {
    return (
      React.createElement(InputNumber, {
        value: value,
        onChange: (nextValue) => setValue(nextValue),
        style: field.style,
        ...field.settings, __self: this, __source: {fileName: _jsxFileName, lineNumber: 312}}
      )
    );
  }

  if (field.type === "switch") {
    return (
      React.createElement(Switch, {
        checked: value,
        onChange: (nextValue) => {
          setValue(nextValue);
        },
        style: field.style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 323}}
      )
    );
  }

  if (field.type === "checkbox") {
    return (
      React.createElement(Checkbox, {
        checked: value,
        onChange: (e) => {
          setValue(e.target.checked);
        },
        style: field.style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 335}}
      )
    );
  }

  if (field.type === "textarea") {
    return (
      React.createElement(Input.TextArea, {
        value: value,
        onChange: (e) => setValue(e.target.value),
        rows: _optionalChain([field, 'access', _10 => _10.settings, 'optionalAccess', _11 => _11.rows]) || 4,
        style: field.style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 347}}
      )
    );
  }

  if (field.type === "select") {
    return (
      React.createElement(Select, {
        placeholder: _optionalChain([field, 'access', _12 => _12.settings, 'optionalAccess', _13 => _13.placeholder]),
        value: value,
        onChange: (v) => {
          setValue(v);
        },
        style: field.style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 358}}

        , _optionalChain([field, 'access', _14 => _14.settings, 'optionalAccess', _15 => _15.options, 'access', _16 => _16.map, 'call', _17 => _17((option) => {
          return (
            React.createElement(Select.Option, { key: option.value, value: option.value, __self: this, __source: {fileName: _jsxFileName, lineNumber: 368}}
              , t(option.label)
            )
          );
        })])
      )
    );
  }

  // if (field.type === "select-item") {
  // return (
  //   <DataItemPicker
  //     value={value}
  //     onChange={(v) => setValue(v)}
  //     style={field.style}
  //   />
  // );
  // }

  console.warn(`FormGenerator: Type '${field.type}' does not exist.`);
  return null;
};

function getSelector(obj, path) {
  try {
    let value = obj;

    if (Array.isArray(path)) {
      for (const key of path) {
        value = value[key];
      }
    } else {
      value = value[path];
    }

    return value;
  } catch (error) {
    return null;
  }
}

function setSelector(obj, path, value) {
  try {
    if (!Array.isArray(path)) {
      obj[path] = value;

      return true;
    }

    if (path.length === 1) {
      return setSelector(obj, path[0], value);
    }

    const [key, nextKey, ...remainingSelector] = path;

    if (!(key in obj)) {
      if (Number.isInteger(nextKey)) {
        obj[key] = [];
      } else {
        obj[key] = {};
      }
    }

    return setSelector(obj[key], [nextKey, ...remainingSelector], value);
  } catch (error) {
    return false;
  }
}
