import { makeAutoObservable } from "mobx";


export class UserService2 {
  

  constructor(framework) {
    makeAutoObservable(this);

    this.framework = framework;
  }
}
