const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/ErrorBoundary.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as React from "react";

import { equals } from "..";
import { ErrorLayout } from "./ErrorBoundary.layout";

export class ErrorBoundary extends React.Component {constructor(...args) { super(...args); ErrorBoundary.prototype.__init.call(this);ErrorBoundary.prototype.__init2.call(this); }
  __init() {this.state = { error: undefined }}

  __init2() {this.resetState = (goHome = false) => {
    if (goHome) {
      return void (window.location.href = "/");
    }

    this.setState({ error: null });

    if (this.props.onErrorReset) {
      this.props.onErrorReset();
    }

    // TODO implement goHome
  }}

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.error &&
      this.props.dependencies &&
      prevProps.dependencies &&
      !equals(prevProps.dependencies, this.props.dependencies)
    ) {
      this.setState({ error: null });
    }
  }

  componentDidCatch(error, info) {
    // console.error("ErrorBoundary error", error);
    // console.error("ErrorBoundary info", info);
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        React.createElement(ErrorLayout, { reset: this.resetState, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
          , React.createElement('h1', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}, "Error")
          , _optionalChain([this, 'access', _ => _.state, 'access', _2 => _2.error, 'optionalAccess', _3 => _3.message]) && React.createElement('h2', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}, this.state.error.message)
          /* <pre>{this.state.error.stack}</pre>*/
        )
      );
    }

    return this.props.children;
  }
}
