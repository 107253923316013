 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import debounce from "lodash.debounce";
import React, { useCallback, useState } from "react";






function getSize(ref) {
  if (!ref || !ref.current) {
    return {
      width: 0,
      height: 0,
    };
  }

  return {
    width: ref.current.offsetWidth,
    height: ref.current.offsetHeight,
  };
}

export function useElementSize(
  ref,
  throttleDuration = 0
) {
  const [state, setState] = useState(getSize(ref));

  function _handleResize() {
    const nextState = getSize(ref);
    const shouldUpdate =
      nextState.width !== state.width || nextState.height !== state.height;

    if (shouldUpdate) {
      setState(nextState);
    }
  }

  const handleResize = useCallback(
    throttleDuration
      ? debounce(_handleResize, throttleDuration)
      : _handleResize,
    [ref, throttleDuration]
  );

  React.useEffect(() => {
    if (!ref || !_optionalChain([ref, 'optionalAccess', _ => _.current])) {
      return;
    }

    handleResize();
    // @ts-ignore // TS doesn't know about ResizeObserver yet
    if (typeof ResizeObserver === "function") {
      // @ts-ignore // TS doesn't know about ResizeObserver yet
      const resizeObserver = new ResizeObserver(() => {
        window.requestAnimationFrame(() => {
          handleResize();
        });
      });

      // @ts-ignore // TS doesn't know about ResizeObserver yet
      resizeObserver.observe(ref.current);

      return () => {
        // @ts-ignore // TS doesn't know about ResizeObserver yet
        resizeObserver.disconnect(ref.current);
      };
    } else {
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [_optionalChain([ref, 'optionalAccess', _2 => _2.current])]);

  return state;
}
