import React from "react";
import { useServiceStore, useUserService } from "..";

export function useCurrentUser() {
  const UserService = useUserService();

  return useServiceStore(
    UserService,
    React.useCallback((state) => state.currentUser, [])
  );
}
