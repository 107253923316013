const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/_layout/LanguageDialog.tsx";import { changeLanguage } from "@opendash/i18n";
import { Modal, Select } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useLocalStorage, useOpenDashApp, useTranslation } from "../..";

export const LanguageDialog = observer(function LanguageDialog() {
  const t = useTranslation();

  const app = useOpenDashApp();

  const [lang, setLang] = useLocalStorage("opendash:language");

  return (
    React.createElement(Modal, {
      title: t("opendash:account.language.label"),
      cancelText: t("opendash:ui.close"),
      open: app.ui2.languageDialogOpen,
      onOk: () => app.ui2.hideLanguageDialog(),
      onCancel: () => app.ui2.hideLanguageDialog(),
      okButtonProps: { style: { display: "none" } }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}

      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}, t("opendash:account.language.info"))
      , React.createElement(Select, {
        placeholder: t("opendash:account.language.placeholder"),
        defaultValue: lang,
        onChange: (nextLang) => {
          changeLanguage(nextLang);
          setLang(nextLang);
        },
        style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}

        , app.ui.languages.map(({ key, label }) => (
          React.createElement(Select.Option, { key: key, value: key, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
            , label
          )
        ))
      )
    )
  );
});
