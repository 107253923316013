const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/_layout/UserPasswordChange.tsx";import { Button, Form, Input, Modal } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  useLocalStorage,
  useOpenDashApp,
  useOpenDashServices,
  useTranslation,
} from "../..";

export const UserPasswordChange = observer(function UserPasswordChange() {
  const t = useTranslation();
  const app = useOpenDashApp();
  const services = useOpenDashServices();

  const [lang, setLang] = useLocalStorage("opendash:language");

  return (
    React.createElement(Modal, {
      title: t("parse-admin:auth.modal_label"),
      cancelText: t("opendash:ui.close"),
      open: app.ui2.resetPWDialogOpen,
      onOk: () => app.ui2.hideResetPWDialog(),
      onCancel: () => app.ui2.hideResetPWDialog(),
      okButtonProps: { style: { display: "none" } }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}

      , React.createElement(Form, {
        onFinish: (data) => {
          services.UserService.changePassword({ password: data.password });
          //$parse.auth.changePassword({ password: data.password });
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}

        , React.createElement(Form.Item, {
          name: "password",
          label: t("parse-admin:auth.field_password_label"),
          required: true,
          tooltip: t("parse-admin:auth.field_password_tooltip"),
          rules: [
            {
              required: true,
              message: t("parse-admin:auth.field_password_validation_required"),
            },
            {
              type: "string",
              min: 8,
              message: t("parse-admin:auth.field_password_validation_length", {
                length: 8 || "",
              }),
            },
          ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}

          , React.createElement(Input.Password, {
            placeholder: t("parse-admin:auth.field_password_placeholder"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
          )
        )

        , React.createElement(Form.Item, {
          name: "password2",
          label: t("parse-admin:auth.field_password2_label"),
          tooltip: t("parse-admin:auth.field_password2_tooltip"),
          dependencies: ["password"],
          rules: [
            {
              required: true,

              message: t(
                "parse-admin:auth.field_password2_validation_required"
              ),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t("parse-admin:auth.field_password2_validation_missmatch")
                );
              },
            }),
          ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}

          , React.createElement(Input.Password, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 82}} )
        )

        , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
          , React.createElement(Button, { type: "primary", htmlType: "submit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
            , t("parse-admin:auth.modal_action")
          )
        )
      )
    )
  );
});
