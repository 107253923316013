const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/_layout/index.tsx";import { Icon } from "@opendash/icons";
import { Button } from "antd";
import * as React from "react";
import {
  $framework,
  useElementSize,
  useOpenDashApp,
  useTranslation,
} from "../..";
import { GlobalStyle } from "./GlobalStyle";
import { HeaderNavigation } from "./HeaderNavigation";
import { HeaderSourcePicker } from "./HeaderSourcePicker";
import { LanguageDialog } from "./LanguageDialog";
import { UserPasswordChange } from "./UserPasswordChange";
import { VersionDialog } from "./VersionDialog";
import * as Layout from "./components";

export default function AppLayout({ children }) {
  const t = useTranslation();

  const app = useOpenDashApp();

  const headerRef = React.useRef();
  const headerSize = useElementSize(headerRef);

  React.useEffect(() => {
    app.ui2.setHeaderSize(headerSize.height, headerSize.width);
  }, [headerSize.height, headerSize.width]);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(GlobalStyle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}} )
      , React.createElement(LanguageDialog, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 33}} )
      , React.createElement(UserPasswordChange, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} )
      , React.createElement(VersionDialog, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 35}} )

      , !app.ui.disableHeader && (
        React.createElement(Layout.Header, { id: "opendash-header", ref: headerRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
          , React.createElement(HeaderNavigation, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} )
          , React.createElement(HeaderSourcePicker, { mobile: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}} )
        )
      )

      , React.createElement(Layout.Layout, { id: "opendash-layout", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        , React.createElement(Layout.Content, { id: "opendash-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
          , React.createElement('div', {
            id: "opendash-ref-content",
            ref: app._refs.content,
            style: { width: "100vw" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
          )

          , children
        )

        , !app.ui.disableFooter && (
          React.createElement(Layout.Footer, { id: "opendash-footer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
            , React.createElement('div', { id: "opendash-ref-footer", ref: app._refs.footer, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}} )

            , React.createElement(Layout.FooterRowCopyright, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
              , ($framework.ui2.copyrightText || null) && (
                React.createElement(Button, {
                  type: "link",
                  href: $framework.ui2.copyrightLink,
                  target: "_blank",
                  children: $framework.ui2.copyrightText, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
                )
              )

              , ($framework.ui2.imprint || null) && (
                React.createElement(Button, {
                  type: "link",
                  href: $framework.ui2.imprint,
                  target: "_blank",
                  children: t("opendash:ui.imprint"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
                )
              )

              , ($framework.ui2.tos || null) && (
                React.createElement(Button, {
                  type: "link",
                  href: $framework.ui2.tos,
                  target: "_blank",
                  children: t("opendash:ui.tos"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
                )
              )

              , ($framework.ui2.dataProtection || null) && (
                React.createElement(Button, {
                  type: "link",
                  href: $framework.ui2.dataProtection,
                  target: "_blank",
                  children: t("opendash:ui.data_protection"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
                )
              )

              , React.createElement(Button, {
                type: "text",
                children: app.ui2.version,
                onClick: () => app.ui2.showVersionDialog(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
              )

              , ($framework.serviceWorker.updateAvailable || null) && (
                React.createElement(Button, {
                  type: "text",
                  onClick: () => $framework.serviceWorker.installUpdateDialog(),
                  children: React.createElement(Icon, { icon: "fa:sync-alt", color: "#52C41A", __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
                )
              )
            )

            , React.createElement('div', {
              id: "opendash-ref-footer-after-copyright",
              ref: app._refs.footerAfterCopyright, __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
            )
          )
        )
      )
    )
  );
}
