const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/HeaderMenuItem.tsx";import * as React from "react";

import { AppPortal, useTranslation } from "..";

import { Icon } from "@opendash/icons";
import { HeaderMenuItem as Menu } from "@opendash/ui";
import { Badge, Tooltip } from "antd";








export const HeaderMenuItem = React.memo(function HeaderMenuItem({
  active,
  icon,
  tooltip,
  onClick,
}) {
  const t = useTranslation();

  return (
    React.createElement(AppPortal, { place: "headerBeforeMenuRight", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      , React.createElement(Tooltip, { title: tooltip, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
        , React.createElement(Menu, {
          onClick: onClick,
          style: {
            backgroundColor: active ? "#eee" : undefined,
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}

          , React.createElement(Badge, { dot: active, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
            , React.createElement(Icon, {
              icon: icon,
              style: {
                color: active ? "var(--ant-primary-color)" : undefined,
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
            )
          )
        )
      )
    )
  );
});
