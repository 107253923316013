 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import produce from "immer";

















export class Store {
  
   __init() {this.watcher = new Set()}
   __init2() {this.selectorCache = new Map()}
  
   __init3() {this._cachedValue = null}

  constructor(initialState, config) {;Store.prototype.__init.call(this);Store.prototype.__init2.call(this);Store.prototype.__init3.call(this);
    this._state = initialState;

    this.config = {
      cacheHandler: _optionalChain([config, 'optionalAccess', _ => _.cacheHandler]) || null,
      cacheKey: _optionalChain([config, 'optionalAccess', _2 => _2.cacheKey]) || null,
      cacheAllowlist: _optionalChain([config, 'optionalAccess', _3 => _3.cacheAllowlist]) || null,
      cacheBlocklist: _optionalChain([config, 'optionalAccess', _4 => _4.cacheBlocklist]) || null,
    };

    this.readCache();
  }

  getState() {
    return this._state;
  }

  select(selector) {
    return selector(this._state);
  }

  setState(state) {
    this._state = state;

    this.watcher.forEach((callback) => {
      try {
        callback(this._state);
      } catch (error) {
        console.error("Error in Store subscription callback:", error);
      }
    });

    this.writeCache();
  }

  update(callbackOrState) {
    if (typeof callbackOrState === "function") {
      this.setState(
        produce(this._state, callbackOrState ) 
      );
    } else {
      this.setState(callbackOrState);
    }
  }

  assignToState(state) {
    this.setState(Object.assign({}, this._state, state));
  }

  subscribe(callback) {
    this.watcher.add(callback);

    return () => {
      this.watcher.delete(callback);
    };
  }

  subscribeSelection(
    selector,
    callback
  ) {
    const selectorCallback = () => {
      const cache = this.selectorCache.get(selector);
      const selection = selector(this._state);

      if (!cache || cache !== selection) {
        this.selectorCache.set(selector, selection);

        callback(selection);
      }
    };

    this.watcher.add(selectorCallback);

    return () => {
      this.watcher.delete(selectorCallback);
      this.selectorCache.delete(selector);
    };
  }

   async readCache() {
    if (this.config.cacheHandler && this.config.cacheKey) {
      try {
        const handler = this.config.cacheHandler;
        const key = `store:cache:${this.config.cacheKey}`;

        // TODO:
        // There is a race condition when services with a cached state are created
        // If the service uses the DeviceStorage service as a cache handler, the adapter
        // is not ready before the state reads the cache
        // This workaround will disable cache handlers and use local storage instead
        // let cache = await handler.get(key);
        let cache = JSON.parse(window.localStorage.getItem(key) || "{}");

        cache = this.handleCacheLists(cache);

        this.assignToState(cache);
      } catch (error) {
        console.error(`@opendash/store: Error while loading cache:`, error);
      }
    }
  }
   async writeCache() {
    if (this.config.cacheHandler && this.config.cacheKey) {
      try {
        const handler = this.config.cacheHandler;
        const key = `store:cache:${this.config.cacheKey}`;

        const cache = this.handleCacheLists(this._state);

        const cacheHash = JSON.stringify(cache);

        if (this._cachedValue !== cacheHash) {
          // TODO:
          // await handler.set(key, cache);
          window.localStorage.setItem(key, JSON.stringify(cache));

          this._cachedValue = cacheHash;

          console.log(`@opendash/store: Cached '${key}':`, cache);
        }
      } catch (error) {
        console.error(`@opendash/store: Error while writing cache:`, error);
      }
    }
  }

   handleCacheLists(state) {
    const result = { ...state };

    if (
      Array.isArray(this.config.cacheAllowlist) &&
      this.config.cacheAllowlist.length > 0
    ) {
      for (const key of Object.keys(result)) {
        if (!this.config.cacheAllowlist.includes(key)) {
          delete result[key];
        }
      }
    }

    if (
      Array.isArray(this.config.cacheBlocklist) &&
      this.config.cacheBlocklist.length > 0
    ) {
      for (const key of Object.keys(result)) {
        if (this.config.cacheBlocklist.includes(key)) {
          delete result[key];
        }
      }
    }

    return result;
  }
}
