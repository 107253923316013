 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { translateSync } from "@opendash/i18n";
import { makeAutoObservable } from "mobx";

import { $framework, } from "..";






export class UserInterfaceService {
  

   __init() {this.servicesLoading = true}

   __init2() {this.userDialogDisabled = false}
   __init3() {this.userDialogOpen = false}

   __init4() {this.versionDialogDisabled = false}
   __init5() {this.versionDialogOpen = false}

   __init6() {this.languageDialogDisabled = false}
   __init7() {this.languageDialogOpen = false}

   __init8() {this.resetPWDialogOpen = false}

   __init9() {this.sidebarVisible = false}
   __init10() {this.sidebarOpen = false}

   __init11() {this.sidebar = {
    available: false,
    open: false,
  }}

   __init12() {this.headerHeight = 0}
   __init13() {this.headerWidth = 0}

   __init14() {this.copyrightText = ""}
   __init15() {this.copyrightLink = ""}
   __init16() {this.imprint = ""}
   __init17() {this.tos = ""}
   __init18() {this.dataProtection = ""}
   __init19() {this.imprintTenant = ""}
   __init20() {this.tosTenant = ""}
   __init21() {this.dataProtectionTenant = ""}

  constructor(app) {;UserInterfaceService.prototype.__init.call(this);UserInterfaceService.prototype.__init2.call(this);UserInterfaceService.prototype.__init3.call(this);UserInterfaceService.prototype.__init4.call(this);UserInterfaceService.prototype.__init5.call(this);UserInterfaceService.prototype.__init6.call(this);UserInterfaceService.prototype.__init7.call(this);UserInterfaceService.prototype.__init8.call(this);UserInterfaceService.prototype.__init9.call(this);UserInterfaceService.prototype.__init10.call(this);UserInterfaceService.prototype.__init11.call(this);UserInterfaceService.prototype.__init12.call(this);UserInterfaceService.prototype.__init13.call(this);UserInterfaceService.prototype.__init14.call(this);UserInterfaceService.prototype.__init15.call(this);UserInterfaceService.prototype.__init16.call(this);UserInterfaceService.prototype.__init17.call(this);UserInterfaceService.prototype.__init18.call(this);UserInterfaceService.prototype.__init19.call(this);UserInterfaceService.prototype.__init20.call(this);UserInterfaceService.prototype.__init21.call(this);
    makeAutoObservable(this);

    this.app = app;
  }

  get name() {
    return _optionalChain([(this.app.config ), 'optionalAccess', _ => _.manifest, 'optionalAccess', _2 => _2.name]) || "?";
  }

  get version() {
    return "v" + (_optionalChain([(this.app.config ), 'optionalAccess', _3 => _3.manifest, 'optionalAccess', _4 => _4.version]) || "0.0.0");
  }

  get primaryColor() {
    return this.app.ui.primaryColor;
  }

   setServicesLoading(value) {
    this.servicesLoading = value;
  }

   showUserDialog() {
    this.userDialogOpen = true;
  }

   hideUserDialog() {
    this.userDialogOpen = false;
  }

   showVersionDialog() {
    this.versionDialogOpen = true;
  }

   hideVersionDialog() {
    this.versionDialogOpen = false;
  }

   showLanguageDialog() {
    this.languageDialogOpen = true;
    this.hideUserDialog();
  }

   showResetPWDialog() {
    console.log("True");
    this.resetPWDialogOpen = true;
    this.hideUserDialog();
  }

   hideResetPWDialog() {
    console.log("false");
    this.resetPWDialogOpen = false;
  }

   hideLanguageDialog() {
    this.languageDialogOpen = false;
  }

   updateSidebar(sidebar) {
    Object.assign(this.sidebar, sidebar);
  }

   setHeaderSize(height, width) {
    try {
      this.headerHeight = height;
      this.headerWidth = width;

      document.documentElement.style.setProperty(
        "--opendash-header-height",
        height + "px"
      );

      document.documentElement.style.setProperty(
        "--opendash-header-width",
        width + "px"
      );
    } catch (error) {
      console.error(error);
    }
  }

   setLinks(
    links









  ) {
    Object.assign(this, links);
  }

   message(
    type,
    msg,
    timeout
  ) {
    msg = translateSync(msg);

    switch (type) {
      case "success":
        return $framework.antd_msg.success(msg, timeout);
      case "warning":
        return $framework.antd_msg.warning(msg, timeout);
      case "error":
        return $framework.antd_msg.error(msg, timeout);
      default:
      case "info":
        return $framework.antd_msg.info(msg, timeout);
    }
  }
}
