import { message } from "antd";







import { Store } from "../classes/Store";
import { CommandBarService } from "./CommandBarService";
import { NavigationService } from "./NavigationService";
import { RouterService } from "./RouterService";
import { ServiceWorkerService } from "./ServiceWorkerService";
import { SourceService } from "./SourceService";
import { StorageService } from "./StorageService";
import { UserInterfaceService } from "./UserInterfaceService";
import { UserService } from "./UserService";
import { UserService2 } from "./UserService2";

message.config({
  top: 50,
});

class AntdMessage {
  success(
    content,
    duration,
    onClose
  ) {
    return message.success(content, duration, onClose);
  }
  info(
    content,
    duration,
    onClose
  ) {
    return message.info(content, duration, onClose);
  }
  warning(
    content,
    duration,
    onClose
  ) {
    return message.warning(content, duration, onClose);
  }
  error(
    content,
    duration,
    onClose
  ) {
    return message.error(content, duration, onClose);
  }
}

export class FrameworkService  {
  __init() {this.id = "opendash"}
  __init2() {this.config = undefined}
  
  

  

  __init3() {this._internal = {
    globalComponents: [],
    providerComponents: [],
    services: [],
    routes: [],
  }}

  __init4() {this._refs = {
    content: { current: null },
    headerBeforeMenuLeft: { current: null },
    headerAfterMenuLeft: { current: null },
    headerBeforeMenuRight: { current: null },
    headerAfterMenuRight: { current: null },
    headerBeforeLogo: { current: null },
    headerAfterLogo: { current: null },
    footer: { current: null },
    footerAfterCopyright: { current: null },
  }}

  __init5() {this.user = new UserService2(this)}
  __init6() {this.router = new RouterService(this)}
  __init7() {this.ui2 = new UserInterfaceService(this)}
  __init8() {this.serviceWorker = new ServiceWorkerService(this)}
  __init9() {this.commands = new CommandBarService(this)}
  __init10() {this.antd_msg = new AntdMessage()}

  constructor() {;FrameworkService.prototype.__init.call(this);FrameworkService.prototype.__init2.call(this);FrameworkService.prototype.__init3.call(this);FrameworkService.prototype.__init4.call(this);FrameworkService.prototype.__init5.call(this);FrameworkService.prototype.__init6.call(this);FrameworkService.prototype.__init7.call(this);FrameworkService.prototype.__init8.call(this);FrameworkService.prototype.__init9.call(this);FrameworkService.prototype.__init10.call(this);
    // @ts-ignore
    window.$framework = this;

    // debugger;
    try {
      this.state = createState();
    } catch (error) {
      console.error(error);
    }

    this.ui = {
      disableHeader: false,
      disableHeaderSourcePicker: false,
      disableFooter: false,
      logoImage: undefined,
      logoText: undefined,
      logoLink: undefined,
      logoLinkExternal: undefined,
      logoMobileSize: 275,

      primaryColor: "",
      backgroundColor: "",
      colorPalette: [],
      languages: [],
      staticNavigationGroups: [],
      staticNavigationItems: [],
    };

    this.services = {
      UserService: new UserService(this),
      SourceService: new SourceService(this),
      NavigationService: new NavigationService(this),
      DeviceStorageService: new StorageService(this),
      UserStorageService: new StorageService(this),
    };
  }

  init(data) {
    if (data.ui) {
      Object.assign(this.ui, data.ui);
    }

    if (data._internal) {
      Object.assign(this._internal, data._internal);
    }

    delete data.ui;
    delete data.ui2;
    delete data.services;
    delete data._internal;

    Object.assign(this, data);

    this.setServicesLoading();

    for (const service of this._internal.services) {
      service.subscribe(() => {
        this.setServicesLoading();
      });
    }

    this.commands.registerCommand({
      id: "opendash:navigation",
      title: "Navigation",
    });

    this.commands.registerCommand({
      id: "opendash:navigation:home",
      parent: "opendash:navigation",
      title: "Home",
      shortcut: ["g", "h"],
      handler: () => {
        this.router.navigate("/");
      },
    });

    for (const nav of this.ui.staticNavigationItems) {
      if (nav.place !== "frontpage") continue;
      if (typeof nav.link !== "string") continue;

      this.commands.registerCommand({
        id: "opendash:navigation:" + nav.id,
        parent: "opendash:navigation",
        title: nav.label,
        handler: () => {
          this.router.navigate(nav.link );
        },
      });
    }

    this.commands.registerCommand({
      id: "opendash:debug",
      title: "Debug",
    });

    this.commands.registerCommand({
      id: "opendash:debug:translations",
      parent: "opendash:debug",
      title: "Debug Translations",
      handler: () => {
        this.router.navigate("/debug/translations");
      },
    });
  }

   isServicesLoading() {
    if (!this.services.UserService.isLoggedIn()) {
      return this.services.UserService.isLoading();
    }

    for (const service of this._internal.services) {
      if (service.isLoading()) {
        return true;
      }
    }

    return false;
  }

   setServicesLoading() {
    const loading = this.isServicesLoading();

    this.ui2.setServicesLoading(loading);
  }
}

function createState() {
  const cacheKey = "opendash/state/cache";
  const cache = loadCache(cacheKey);

  const store = new Store(
    cache || {
      sources: {
        current: undefined,
        all: [],
      },
      navigation: {
        userNavigationGroups: [],
        userNavigationItems: [],
      },
    }
  );

  store.subscribe(() => {
    saveCache(cacheKey, store.getState());
  });

  return store;
}

function loadCache(cacheKey) {
  try {
    return JSON.parse(window.localStorage.getItem(cacheKey));
  } catch (error) {
    return null;
  }
}

function saveCache(cacheKey, state) {
  try {
    window.localStorage.setItem(cacheKey, JSON.stringify(state));
  } catch (error) {
    console.error(`Error writing ${cacheKey}`, error);
  }
}
