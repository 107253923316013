const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/_layout/HeaderSourcePicker.tsx";import { observer } from "mobx-react-lite";
import * as React from "react";
import { LinkedSourcePicker, useOpenDashApp, useSource } from "../..";
import * as Layout from "./components";






export const breakpoint = 600;

export const HeaderSourcePicker = observer(function HeaderSourcePicker({
  mobile: isMobile,
  style,
}) {
  const app = useOpenDashApp();
  const [source, setSource, sources] = useSource();

  if (!sources || sources.length === 0) {
    return null;
  }

  if (isMobile) {
    if (app.ui2.headerWidth >= breakpoint) {
      return null;
    }

    return (
      React.createElement(Layout.HeaderRow, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
        , React.createElement(LinkedSourcePicker, {
          asButton: true,
          style: { width: "100%", height: "var(--opendash-header-row-height)" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        )
      )
    );
  }

  if (source && sources.length === 1) {
    return (
      React.createElement('span', {
        style: { color: "var(--opendash-color-darkgray)", userSelect: "none" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}

        , source.name
      )
    );
  }

  return React.createElement(LinkedSourcePicker, { asButton: false, style: style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} );
});
