import * as React from "react";
import * as ReactDOM from "react-dom";
import { useOpenDashApp } from "..";





export const AppPortal = React.memo(
  function AppPortal({ place, children }) {
    const app = useOpenDashApp();

    if (!app._refs[place].current) {
      return null;
    }

    return ReactDOM.createPortal(children, app._refs[place].current);
  }
);
