const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/_layout/VersionDialog.tsx";import { Modal } from "antd";
import { observer } from "mobx-react-lite";
import React from "react";
import { useOpenDashApp, useTranslation } from "../..";

export const VersionDialog = observer(function VersionDialog() {
  const t = useTranslation();
  const app = useOpenDashApp();

  return (
    React.createElement(Modal, {
      title: t("opendash:ui.version"),
      okText: t("opendash:ui.version_force_update"),
      cancelText: t("opendash:ui.close"),
      open: app.ui2.versionDialogOpen,
      children: t("opendash:ui.version_build", { version: app.ui2.version }),
      okType: "danger",
      onOk: async () => {
        try {
          if ("serviceWorker" in navigator) {
            const reg = await navigator.serviceWorker.getRegistration();

            if (reg) {
              await reg.unregister();
            }
          }
        } catch (error) {
          console.log(error);
        }

        try {
          if (window.caches) {
            const caches = window.caches;
            const cacheKeys = await caches.keys();

            for (const cacheKey of cacheKeys) {
              await caches.delete(cacheKey);
            }
          }
        } catch (error) {
          console.log(error);
        }

        try {
          window.localStorage.clear();
        } catch (error) {
          console.log(error);
        }

        window.location.reload();
      },
      onCancel: () => {
        app.ui2.hideVersionDialog();
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
    )
  );
});
