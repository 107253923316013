const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/RouterErrorHandler.tsx";import { Icon } from "@opendash/icons";
import { Navigate } from "@opendash/router";
import { useRouteError } from "@opendash/router/dist/internal";
import { Button, Result } from "antd";
import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "..";

const Container = styled.div`
  width: 100%;

  .ant-result {
    width: 100%;
  }
`;

export const RouterErrorHandler = React.memo(function RouterErrorHandler() {
  const t = useTranslation();
  const error = useRouteError();

  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    if (counter > 0 && typeof error === "object" && "status" in error) {
      if (error.status === 401) {
        setTimeout(() => {
          setCounter((c) => c - 1);
        }, 1000);
      }
    }
  }, [counter]);

  React.useEffect(
    () => void console.error("Error in Routing Action:", error),
    [error]
  );

  let title = "opendash:error.internal_title";
  let subTitle = "opendash:error.internal_subtitle";
  let action = "opendash:error.action_home";
  let href = "/";
  let suffix = "";

  if (typeof error === "object" && "status" in error) {
    if (error.status === 401) {
      title = "opendash:error.unauthorized_title";
      subTitle = "opendash:error.unauthorized_subtitle";
      action = "opendash:error.action_login";
      href = "/auth/login?url=" + encodeURIComponent(window.location.pathname);

      suffix = ` (${counter}s)`;
    }

    if (error.status === 403) {
      title = "opendash:error.permission_denied_title";
      subTitle = "opendash:error.permission_denied_subtitle";
    }

    if (error.status === 404) {
      title = "opendash:error.not_found_title";
      subTitle = "opendash:error.not_found_subtitle";
    }
  }

  if (counter === 0) {
    return React.createElement(Navigate, { to: href, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}} );
  }

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
      , React.createElement(Result, {
        status: "info",
        icon: React.createElement(Icon, { icon: "fa:exclamation-circle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}} ),
        title: t(title),
        subTitle: t(subTitle),
        extra: [
          React.createElement(Button, {
            key: "home",
            type: "primary",
            children: t(action) + suffix,
            onClick: (e) => {
              window.location.href = href;
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
          ),
          // <Button
          //   children={t("opendash:error.reset")}
          //   onClick={(e) => {
          //     reset(true);
          //   }}
          // />,
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
      )
    )
  );
});
