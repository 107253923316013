const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/_provider/CommandBar/CommandBarProvider.tsx";import {
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarSearch,
} from "kbar";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { $framework } from "../../../exports";
import { CommandBarActions } from "./CommandBarActions";
import { CommandBarResults } from "./CommandBarResults";
import { CommandBarStyle } from "./CommandBarStyle";

export const CommandBarProvider = observer(
  function CommandBarProvider({ children }) {
    return (
      React.createElement(KBarProvider, { actions: $framework.commands.actions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
        , React.createElement(CommandBarStyle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} )
        , React.createElement(CommandBarActions, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} )
        , React.createElement(KBarPortal, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
          , React.createElement(KBarPositioner, { className: "opendash-commandbar--positioner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
            , React.createElement(KBarAnimator, { className: "opendash-commandbar--animator", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
              , React.createElement(KBarSearch, { className: "opendash-commandbar--search", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )
              , React.createElement(CommandBarResults, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}} )
            )
          )
        )
        , children
      )
    );
  }
);
