const _jsxFileName = "/Users/nico/Developer/open.DASH/MonoV3/opendash/libs/core/src/components/Loading.tsx";import * as React from "react";

import { Spin } from "antd";

export function Loading({ message }) {
  return (
    React.createElement('div', {
      style: {
        height: "100%",
        width: "100%",
        padding: 50,
        textAlign: "center",
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}

      , React.createElement(Spin, {
        style: {
          height: "100%",
          width: "100%",
          padding: 50,
          textAlign: "center",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
      )
      , message && React.createElement('div', { style: { paddingTop: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}, message)
    )
  );
}
