import "antd/dist/reset.css";
//import "./highcharts.config";
import "./leaflet.config";
import "./parse.config";

import { init, StorageAdapterLS } from "@opendash/core";
import { getCurrentLanguageSync } from "@opendash/i18n";
import { registerIconPack } from "@opendash/icons";
import { BDEPlugin } from "@opendash/plugin-bde";
import { FeedbackPlugin } from "@opendash/plugin-feedback";
import { GeoPlugin } from "@opendash/plugin-geo";
import { GeoPluginMapLibre } from "@opendash/plugin-geo-maplibre";
import { GTFSPlugin } from "@opendash/plugin-gtfs";
import { HighchartsPlugin } from "@opendash/plugin-highcharts";
import { KnowledgePlugin } from "@opendash/plugin-knowledge";
import { MobilityPlugin } from "@opendash/plugin-mobility";
import { MonitoringPlugin } from "@opendash/plugin-monitoring";
import { OpenServicePlugin } from "@opendash/plugin-openservice";
import { OpenwarePlugin } from "@opendash/plugin-openware";
import { $parse, ParsePlugin } from "@opendash/plugin-parse";
import { ParseMonitoringPlugin } from "@opendash/plugin-parse-monitoring";
import { PlotlyPlugin } from "@opendash/plugin-plotly";
import { TimeseriesPlugin } from "@opendash/plugin-timeseries";
import dayjs from "dayjs";
import OverViewComponent from "./components/OverViewComponent";
import clientselector from "./config";

import timezone from "dayjs/plugin/timezone";

init("opendash", async (factory) => {
  // Logo
  if (clientselector.getLogoImage()) {
    factory.ui.setLogoImage(clientselector.getLogoImage());
  }

  // factory.ui.setLogoLink("/");
  // factory.ui.setLogoLinkExternal(true);
  // factory.ui.disableFooter();

  // Icons
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-solid.json"));

  // Translations:
  factory.registerLanguage("en", "English");
  factory.registerLanguage("zh_Hans", "Chinese");
  factory.registerLanguage("de", "Deutsch", "en", true);

  //@ts-ignore
  const params = new URL(document.location).searchParams;
  const embedded = params.get("embedded");

  if (embedded) {
    factory.ui.disableHeader();
    factory.ui.disableFooter();
  }

  factory.registerAntDesignTranslation(
    "en",
    () => import("antd/lib/locale/en_US")
  );

  factory.registerAntDesignTranslation(
    "de",
    () => import("antd/lib/locale/de_DE")
  );

  factory.registerAntDesignTranslation(
    "zh_Hans",
    () => import("antd/lib/locale/zh_CN")
  );

  // parse class translations

  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    async () => await import("./translations/parse/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "parse-custom",
    async () => await import("./translations/parse/en.json")
  );

  factory.registerTranslationResolver(
    "zh_Hans",
    "parse-custom",
    async () => await import("./translations/parse/zh_Hans.json")
  );

  factory.registerTranslationResolver(
    "de",
    "widgets",
    async () => await import("./translations/widgets/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "widgets",
    async () => await import("./translations/widgets/en.json")
  );

  factory.registerTranslationResolver(
    "zh_Hans",
    "widgets",
    async () => await import("./translations/widgets/zh_Hans.json")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());
  await factory.use(
    new ParsePlugin({
      authLdapActive: false,
      useLiveQueries: false,
    })
  );
  await factory.use(new TimeseriesPlugin());

  await factory.use(new MonitoringPlugin());
  await factory.use(new GeoPlugin());
  await factory.use(new GeoPluginMapLibre());
  await factory.use(new OpenServicePlugin());
  await factory.use(new GTFSPlugin());
  await factory.use(new KnowledgePlugin());
  await factory.use(new PlotlyPlugin());
  await factory.use(new BDEPlugin());
  await factory.use(
    new FeedbackPlugin({
      feedbackURL: clientselector.getFeedbackURL(),
      feedbackAssigned: clientselector.getFeedbackAssigned(),
    })
  );

  await factory.use(
    new ParseMonitoringPlugin({
      liveQueries: false,
    })
  );

  await factory.use(
    new OpenwarePlugin({
      secure: clientselector.getOpenWareSecure(),
      host: clientselector.getOpenWareHost(),
      filterValuesOlderThanMS: -1,
      disableFeature: {
        menu: {
          SensorsGroup: false,
          DataPoints: false,
          DataSources: false,
        },
        slideshow: false,
        dataCollection: false,
        VKPI: false,
        forms: {
          dateBySensor: false,
        },
        reporting: false,
      },
    })
  );

  await factory.use(new HighchartsPlugin());

  addcss(`
  .ant-modal-close-x {margin-top:16}
  .ant-steps-item-icon span {line-height:32px!important}
  .ant-steps-item-icon svg {margin-top:7}
  .ant-drawer-body .data-item-boolean {margin-top:12} 
  .ant-drawer-body .data-item-percentage {margin-top:10}
  .leaflet-top {z-index:998!important} 

  `);
  //@ts-ignore
  factory.setPrimaryColor("#666767");
  await factory.use(new MobilityPlugin());

  // await factory.use(new GreisPlugin());

  // factory.ui.disableHeader();

  // Widgets

  //$monitoring.registerWidget(await import("./widgets/kpi-map"));

  // Routing:

  // factory.registerRoute({
  //   path: "/route/to/*",
  //   component: () => import("./path/to/component")
  // });

  // Navigation:

  factory.registerStaticNavigationItem({
    id: "monitoring/dashboard",
    group: "monitoring",
    place: "frontpage",
    order: 1,
    label: "opendash:monitoring.label",
    icon: "fa:chart-line",
    color: "#4385c2",
    link: "/monitoring/dashboards",
    routeCondition: "**",
    activeCondition: "/",
  });

  factory.registerStaticNavigationGroup({
    label: "parse-admin:admin.label",
    order: 10,
    id: "admin/parse",
  });
  factory.registerStaticNavigationGroup({
    label: "openware:reporting.label",
    order: 30,
    id: "admin/reporting",
  });
  factory.registerStaticNavigationItem({
    id: "admin/parse/item",
    group: "admin/parse",
    place: "frontpage",
    order: 100,
    label: "opendash:admin.label",
    icon: "fa:cogs",
    color: "#676767",
    link: "/admin/parse/_Role",
    routeCondition: "**",
    activeCondition: "/",
    permission: "parse-admin",
  });
  factory.registerStaticNavigationItem({
    id: "admin/parse/role",
    group: "admin/parse",
    place: "sidebar",
    order: 20,
    label: "parse-custom:classes._Role.label_plural",
    icon: "fa:building",
    link: "/admin/parse/_Role",
    routeCondition: "/admin/**",
    activeCondition: "/admin/parse/_Role",
    permission: "parse-admin",
  });
  factory.registerStaticNavigationItem({
    id: "admin/parse/user",
    group: "admin/parse",
    place: "sidebar",
    order: 10,
    label: "parse-custom:classes._User.label_plural",
    icon: "fa:users",
    link: "/admin/parse/_User",
    routeCondition: "/admin/**",
    activeCondition: "/admin/parse/_User",
    permission: "parse-admin",
  });

  factory.registerStaticNavigationItem({
    id: "admin/parse/presets",
    group: "admin/parse",
    place: "sidebar",
    order: 40,
    label: "parse-custom:classes.OD3_WidgetPreset.label",
    icon: "fa:clipboard",
    link: "/admin/parse/OD3_WidgetPreset",
    routeCondition: "/admin/**",
    activeCondition: "/admin/parse/OD3_WidgetPreset",
    permission: "parse-admin",
  });

  factory.registerStaticNavigationItem({
    id: "admin/parse/permission",
    group: "admin/parse",
    place: "sidebar",
    order: 30,
    label: "parse-custom:classes.OD3_Permission.label",
    icon: "fa:lock",
    link: "/admin/parse/OD3_Permission",
    routeCondition: "/admin/**",
    activeCondition: "/admin/parse/OD3_Permission",
    permission: "parse-admin",
  });

  // ///////////////////////////////

  $parse.ui.setClassConfig({
    className: "OD3_Source",
    titleFields: ["name"],
    displayFields: ["name", "tag", "updatedAt", "parent"],
    createFields: ["name", "tag", "parent"],
    editFields: ["name", "parent"],
  });
  $parse.ui.setClassConfig({
    className: "OD3_Dashboard",
    titleFields: ["name"],
    displayFields: ["name", "source"],
    createFields: ["name"],
    editFields: ["name"],
  });

  $parse.ui.setClassConfig({
    className: "OD3_WidgetPreset",
    titleFields: ["label"],
    displayFields: ["label", "tags", "description"],
    createFields: null,
    editFields: ["label", "tags", "description"],
  });

  $parse.ui.setClassConfig({
    className: "OD3_Permission",
    titleFields: ["label"],
    displayFields: ["label", "description", "key"],
    createFields: ["label", "description", "key"],
    editFields: ["label", "description", "key"],
  });

  $parse.ui.setDefaultView("OD3_WidgetPreset", {
    type: "table",
  });
  $parse.ui.setDefaultView("OD3_Source", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "_Role",
    titleFields: ["label"],
    displayFields: ["label", "name", "users", "createdAt", "updatedAt"],
    createFields: ["label", "name"],
    editFields: ["label", "name", "users"],
    defaultACL: () => {
      if (!$parse.user.id()) {
        return {};
      }

      return {
        [$parse.user.id()]: {
          read: true,
          write: true,
        },
      };
    },
  });
  $parse.ui.setDefaultView("_Role", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "BCM_Project",
    disableACLEditing: true,
    titleFields: ["label"],
    displayFields: ["label", "description"],
    createFields: ["label", "description"],
    editFields: ["label", "description"],
  });

  $parse.ui.setDefaultView("BCM_Project", {
    type: "table",
    fields: ["label", "description"],
  });

  $parse.ui.setClassConfig({
    className: "BCM_TaskState",
    disableACLEditing: true,
    titleFields: ["label"],
    displayFields: ["label", "description"],
    createFields: ["label", "description"],
    editFields: ["label", "description"],
    orderField: "order",
  });

  $parse.ui.setDefaultView("BCM_TaskState", {
    type: "table",
    fields: ["label", "description"],
  });

  $parse.ui.setClassConfig({
    className: "BCM_Task",
    disableACLEditing: true,
    titleFields: ["label"],
    displayFields: [
      "project",
      "state",
      "label",
      "parent",
      "open",
      "progress",
      "startDate",
      "endDate",
      "assignedTo",
    ],
    createFields: [
      "project",
      "state",
      "label",
      "parent",
      "startDate",
      "endDate",
      "assignedTo",
    ],
    editFields: [
      "project",
      "state",
      "label",
      "parent",
      "open",
      "progress",
      "startDate",
      "endDate",
      "assignedTo",
    ],
    orderField: "order",
  });

  $parse.ui.setDefaultView("BCM_Task", {
    type: "canban",
    groupByField: "state",
    titleFields: ["label"],
    descriptionFields: ["startDate", "endDate"],
  });
  factory.registerRoute({
    path: "/overview/*",
    props: {},
    component: async () => ({ default: OverViewComponent }),
  });
}).then((app) => {
  console.log("init open.DASH");
  dayjs.locale(getCurrentLanguageSync());
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Europe/Berlin");
});

function addcss(css) {
  const head = document.getElementsByTagName("head")[0];
  const s = document.createElement("style");
  s.setAttribute("type", "text/css");
  s.appendChild(document.createTextNode(css));
  head.appendChild(s);
}
